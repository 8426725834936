<template>
  <div class="customer-list">
    <pagoda-form-table-layout>
      <!-- 表单 -->
      <template slot="form" slot-scope="scope">
        <el-form class="el-form-reset" inline>
          <el-form-item label="企业标签" prop="tag_id">
            <el-cascader
              placeholder="请选择企业标签"
              v-model="enterprise_label"
              :options="companyTags"
              :props="{ multiple: true }"
              @change="handleChangeTag"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <el-input
              placeholder="请输入标题"
              v-model="formData.title"
            ></el-input>
          </el-form-item>
          <!-- 表单操作按钮 -->
          <div class="pagoda-button-group" v-bind="scope.btnPosition">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </div>
        </el-form>
        <el-button class="group-btn" type="primary" @click="handleEdit(1)">
          添加
        </el-button>
      </template>
      <!-- 实现一屏展示时 一定要配置表格高度 -->
      <template slot="table" slot-scope="scope">
        <el-table
          ref="table"
          :data="tableData"
          :height="scope.height"
          v-loading="isTableLoading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <el-table-column
            prop="proposal_title"
            width="150"
            label="标题"
            min-width="200"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="title"
            width="150"
            label="素材标题"
          ></el-table-column>
          <el-table-column prop="label" min-width="150" label="企业标签">
            <template slot-scope="scope">
              <!-- <div
                class="company-tag"
                v-for="(item, index) in scope.row.label"
                :key="index"
              >
                {{ tage_name }}
              </div> -->
              <div class="company-tag">{{ scope.row.tage_name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="trigger_behavior"
            width="150"
            label="触发行为"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            width="150"
            label="创建时间"
          ></el-table-column>
          <el-table-column
            prop="username"
            width="150"
            label="创建人"
          ></el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(2, scope.row.id)">
                编辑
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row.id)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <template slot="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :current-page="pageNumber"
          :total="totalDataNum"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </template>
    </pagoda-form-table-layout>
  </div>
</template>
<script>
import customer from '@/services/api/customer'
import reach from '@/services/api/reach'
export default {
  data() {
    return {
      formData: {
        page: 1,
        page_size: 10
      },
      enterprise_label: [],
      tableData: [],
      customerTypeList: [],
      customerStatusList: [],
      companyTags: [],
      totalDataNum: 0,
      customerNum: 0,
      pageSize: 10,
      pageNumber: 1,
      isTableLoading: false
    }
  },
  methods: {
    handleSubmit() {
      const params = this.formData
      reach
        .getProposalList(params)
        .then((res) => {
          this.tableData = res.data.list || []
          this.totalDataNum = res.data.count || 0
        })
        .catch(() => {})
    },
    handleSearch() {
      this.formData.page = 1
      this.pageNumber = 1
      this.handleSubmit()
    },
    handleReset() {
      this.enterprise_label = []
      this.formData = {
        page: 1,
        page_size: 10
      }
      this.pageNumber = 1
      this.pageSize = 10
      this.handleSubmit()
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.handleSubmit()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.handleSubmit()
    },
    // 获取企业标签
    getCompanyTag() {
      customer
        .getCompanyTag()
        .then((res) => {
          const data = res.data
          data.forEach((item) => {
            item.label = item.group_name
            item.value = item.group_name
            item.group_list.forEach((item) => {
              item.label = item.label_name
              item.value = item.id
            })
            item.children = item.group_list
            this.companyTags.push(item)
          })
        })
        .catch(() => {})
    },
    deleteProposal(id) {
      reach
        .deleteProposal(id)
        .then((res) => {
          this.handleReset()
        })
        .catch(() => {})
    },
    handleChangeTag(arr) {
      this.formData.tag_id = ''
      arr.forEach((item) => {
        this.formData.tag_id += item[1] + ','
      })
      this.formData.tag_id = this.formData.tag_id.substring(
        0,
        this.formData.tag_id.length - 1
      )
    },
    // 至编辑页
    handleEdit(type, id) {
      this.$router.push({
        path: '/marketProposalEdit',
        query: {
          type,
          id
        }
      })
    },
    handleDelete(id) {
      this.$confirm('确定删除这条营销建议吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteProposal(id)
        })
        .catch(() => {})
    }
  },
  created() {
    this.getCompanyTag()
    this.handleSubmit()
  },
  activated() {
    // 刷新列表数据
    this.refreshListData()
    this.$nextTick(() => {
      // 切换页面后表格偏移问题，对table进行重新布局
      this.$refs['table'].doLayout()
    })
  }
}
</script>

<style lang="less">
.customer-list {
  height: 100%;
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 220px;
  }
}
.customer-type {
  display: flex;
  line-height: 40px;
  .el-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
}
.total-number {
  margin-top: 10px;
  padding: 10px 0 20px;
  border-top: 1px solid #e1e6eb;
}
.company-tag {
  display: inline-block;
  background-color: #00c587;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  margin: 3px;
}
</style>
